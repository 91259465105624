/* eslint-disable react/display-name */
import React from 'react';
import routesPath from 'config/routesPath';
import OrderSteps from 'modules/order/pages/OrderSteps';
import lazyRetry from 'helpers/lazyRetry';
// import CorporateSalesManagerConfig from 'pages/CorporateSalesManagerConfig';

//LANDING_PAGE_MODULE
const Landing = React.lazy(() => lazyRetry(() => import('pages/landing')));
//MY_RESELLERS_PAGE
const MyResellers = React.lazy(() =>
  lazyRetry(() => import('pages/myResellers'))
);
//HOME
const Home = React.lazy(() => lazyRetry(() => import('pages/home')));
const Welcome = React.lazy(() => lazyRetry(() => import('pages/welcome')));
const FirstAccess = React.lazy(() =>
  lazyRetry(() => import('pages/firstAccess'))
);
// FEIRÃO
const Feirao = React.lazy(() =>
  lazyRetry(() => import('modules/bigDeal/pages'))
);
const KeycloakAuth = React.lazy(() =>
  lazyRetry(() => import('pages/keycloakAuth'))
);
//EVALUATION_MODULE
const EvaluationList = React.lazy(() =>
  lazyRetry(() => import('modules/evaluation/pages/evaluationList'))
);
const EvaluationReports = React.lazy(() =>
  lazyRetry(() => import('modules/evaluation/pages/evaluationReports'))
);
const EvaluationConfig = React.lazy(() =>
  lazyRetry(() => import('modules/evaluation/pages/evaluationConfig'))
);
const NewEvaluation = React.lazy(() =>
  lazyRetry(() => import('modules/evaluation/pages/newEvaluation'))
);
//PATIO_SYSTEM_CONTROL_MODULE
const PatioSystemControl = React.lazy(() =>
  lazyRetry(() => import('modules/patioSystemControl/pages/PatioSystemControl'))
);

const PatioRegister = React.lazy(() =>
  lazyRetry(() => import('modules/patioSystemControl/pages/PatioRegister'))
);

const PatioEdit = React.lazy(() =>
  lazyRetry(() => import('modules/patioSystemControl/pages/PatioRegister'))
);

const SuccessfulRegistration = React.lazy(() =>
  lazyRetry(() =>
    import('modules/patioSystemControl/pages/SuccessfulRegistration')
  )
);

const PatioVehicleDetails = React.lazy(() =>
  lazyRetry(() =>
    import('modules/patioSystemControl/pages/PatioVehicleDetails')
  )
);

//MY_DEALS_MODULE
const MyDeals = React.lazy(() => lazyRetry(() => import('modules/myDeals')));
const DealForm = React.lazy(() => lazyRetry(() => import('pages/dealForm')));
const Performance = React.lazy(() =>
  lazyRetry(() => import('pages/performance'))
);
const StockPublicationSettings = React.lazy(() =>
  lazyRetry(() => import('modules/stockPublication/SettingsView'))
);

//CORPORATE_SALES_MANAGER
const CorporateSalesManagerConfig = React.lazy(() =>
  lazyRetry(() => import('modules/CorporateSalesManagerConfig'))
);

//LEAD_MANAGER_MODULE
const Propostas = React.lazy(() => lazyRetry(() => import('pages/proposals')));
const Conversations = React.lazy(() =>
  lazyRetry(() => import('pages/conversations'))
);
const ActionPlans = React.lazy(() =>
  lazyRetry(() =>
    import(
      'pages/groupView/tabs/ActionPlansView/modules/StoreActionPlan/StoreActionPlan.tsx'
    )
  )
);
const Approvals = React.lazy(() => lazyRetry(() => import('pages/Approvals')));
const ProposalsConfig = React.lazy(() =>
  lazyRetry(() => import('pages/proposalsConfig'))
);
const ManageReports = React.lazy(() =>
  lazyRetry(() => import('modules/manageReports'))
);
const GoalManager = React.lazy(() =>
  lazyRetry(() => import('modules/goalManager/pages/Dashboard'))
);
//LEAD_QUALIFIER
const LeadQualifier = React.lazy(() =>
  lazyRetry(() => import('modules/leadQualifier/page/LeadQualifier'))
);

//CHAT_PROPOSAL_ROUTE
// const ChatProposal = React.lazy(() =>
//   lazyRetry(() => import('pages/chatProposal'))
// );
const StoreInventory = React.lazy(() =>
  lazyRetry(() => import('modules/store-inventory/pages/StoreInventory'))
);
const Reception = React.lazy(() =>
  lazyRetry(() => import('modules/receptionistSchedule/pages/Dashboard'))
);
//SVM_MODULE
const Financing = React.lazy(() =>
  lazyRetry(() => import('modules/financing/pages/FinancingList'))
);
const NewFinancingSteps = React.lazy(() =>
  lazyRetry(() => import('modules/financing/pages/NewFinancingSteps'))
);
//FINANCIAL_INSTITUTIONS_MODULE
const FinancialInstitutionsList = React.lazy(() =>
  lazyRetry(() =>
    import(
      'modules/financing/pages/FinancialInstitution/pages/FinancialInstitutionsList'
    )
  )
);
const FinancialInstitutionDetails = React.lazy(() =>
  lazyRetry(() =>
    import(
      'modules/financing/pages/FinancialInstitution/pages/FinancialInstitutionDetails'
    )
  )
);
const NewFinancialInstitution = React.lazy(() =>
  lazyRetry(() =>
    import(
      'modules/financing/pages/FinancialInstitution/pages/NewFinancialInstitution'
    )
  )
);
const NewCampaign = React.lazy(() =>
  lazyRetry(() =>
    import(
      'modules/financing/pages/FinancialInstitution/pages/Campaign/NewCampaign'
    )
  )
);
const EditCampaign = React.lazy(() =>
  lazyRetry(() =>
    import(
      'modules/financing/pages/FinancialInstitution/pages/Campaign/EditCampaign'
    )
  )
);
const NewTable = React.lazy(() =>
  lazyRetry(() =>
    import('modules/financing/pages/FinancialInstitution/pages/Table/NewTable')
  )
);
const EditTable = React.lazy(() =>
  lazyRetry(() =>
    import('modules/financing/pages/FinancialInstitution/pages/Table/EditTable')
  )
);
//RESALE_MODULE
const ResaleDealForm = React.lazy(() =>
  lazyRetry(() => import('modules/resale/pages/sell/dealForm'))
);
const ResaleStock = React.lazy(() =>
  lazyRetry(() => import('modules/resale/pages/sell/stock'))
);
const ResaleProposals = React.lazy(() =>
  lazyRetry(() => import('modules/resale/pages/sell/proposals'))
);
const ResaleProposalsByDeal = React.lazy(() =>
  lazyRetry(() => import('modules/resale/pages/sell/proposalsByDeal'))
);
const ResaleOfferPortal = React.lazy(() =>
  lazyRetry(() => import('modules/resale/pages/buy/offerPortal'))
);
const ResaleBuyProposals = React.lazy(() =>
  lazyRetry(() => import('modules/resale/pages/buy/proposals'))
);
const ResaleBuyNegotiationByDeal = React.lazy(() =>
  lazyRetry(() => import('modules/resale/pages/buy/proposalsByDeal'))
);
const ResaleDealDetails = React.lazy(() =>
  lazyRetry(() => import('modules/resale/pages/buy/dealDetails'))
);
const ResaleSendSuccessful = React.lazy(() =>
  lazyRetry(() => import('modules/resale/pages/buy/proposalSendSuccessful'))
);
const ResalePurchaseSuccessful = React.lazy(() =>
  lazyRetry(() => import('modules/resale/pages/buy/purchaseSuccessful'))
);
const ResaleConfig = React.lazy(() =>
  lazyRetry(() => import('modules/resale/pages/resaleConfig'))
);
//CONTACTS_MODULE
const ContactList = React.lazy(() =>
  lazyRetry(() => import('modules/managementContact/contactList'))
);
const EditContact = React.lazy(() =>
  lazyRetry(() => import('modules/managementContact/editContact'))
);
const CreateContact = React.lazy(() =>
  lazyRetry(() => import('modules/managementContact/createContact'))
);
//SCHEDULE_MODULE
const ResaleSchedulerContacts = React.lazy(() =>
  lazyRetry(() => import('modules/resale/pages/sell/Schedules'))
);
//INTEGRATION_MODULE
const GerenciarPortais = React.lazy(() =>
  lazyRetry(() => import('pages/manageProviders'))
);
//MOBI-FONE_MODULE
const MobiPhone = React.lazy(() => lazyRetry(() => import('pages/mobiPhone')));
//DEALER_SITE_MODULE
const DealerSite = React.lazy(() =>
  lazyRetry(() => import('pages/dealerSite'))
);
//SOLUTION_MODULE
const Solutions = React.lazy(() => lazyRetry(() => import('pages/solutions')));
//SETTINGS_MODULE
const FormDealer = React.lazy(() =>
  lazyRetry(() => import('pages/formDealer'))
);
// const Settings =  React.lazy(() => lazyRetry(() => import('pages/settings')));
//CALL_CENTER_MODULE
const CallCenter = React.lazy(() =>
  lazyRetry(() => import('pages/callCenter'))
);
//SEARCH_AND_RESCUE_CENTER_MODULE
const SearchAndRescueCenter = React.lazy(() =>
  lazyRetry(() => import('pages/searchAndRescueCenter'))
);
//GROUP_VIEW_MODULE
const GroupViewDashboard = React.lazy(() =>
  lazyRetry(() => import('pages/groupView/tabs/Dashboard'))
);
const GroupViewStock = React.lazy(() =>
  lazyRetry(() => import('pages/groupView/tabs/StockView'))
);
const GroupViewProposals = React.lazy(() =>
  lazyRetry(() => import('pages/groupView/tabs/ProposalView'))
);
const GroupViewApprovals = React.lazy(() =>
  lazyRetry(() => import('pages/groupView/tabs/ApprovalsView'))
);
const GroupViewEvaluations = React.lazy(() =>
  lazyRetry(() => import('pages/groupView/tabs/Evaluation'))
);
const GroupViewMobiPhone = React.lazy(() =>
  lazyRetry(() => import('pages/groupView/tabs/MobifoneView'))
);
const GroupViewActionPlans = React.lazy(() =>
  lazyRetry(() => import('pages/groupView/tabs/ActionPlansView'))
);

const GroupViewStoreActionPlan = React.lazy(() =>
  lazyRetry(() =>
    import('pages/groupView/tabs/ActionPlansView/modules/StoreActionPlan')
  )
);
const GroupViewBillingCenter = React.lazy(() =>
  lazyRetry(() => import('pages/groupView/tabs/BillingCenter'))
);

//REGISTER_DEALER
const ActivationDealer = React.lazy(() =>
  lazyRetry(() => import('pages/activationDealer'))
);
//ERROR_500_PAGE
const Error500 = React.lazy(() => lazyRetry(() => import('pages/error500')));
//ERROR_404_PAGE
const Error404 = React.lazy(() => lazyRetry(() => import('pages/error404')));
//REDIRECT_PAGE
const RedirectPage = React.lazy(() =>
  lazyRetry(() => import('pages/redirectToMyResellers'))
);
const CheckAndRedirect = React.lazy(() =>
  lazyRetry(() => import('pages/redirectToMyResellers/CheckAndRedirect'))
);
//TODO: verificar se vai ser utilizado
// const FluxoDeAdesao =  React.lazy(() => lazyRetry(() => import('pages/fluxoDeAdesao')));
//TODO: verificar se vai ser utilizado
// const Cadastro =  React.lazy(() => lazyRetry(() => import('pages/cadastro')));
//TODO: verificar se vai ser utilizado
// const FormCadastroKeycloak =  React.lazy(() => lazyRetry(() =>
//   import('components/FormCadastroKeycloak')
// );
//TODO: verificar se vai ser utilizado
const MobiFi = React.lazy(() => lazyRetry(() => import('pages/mobiFi')));

const ManageDeals = React.lazy(() =>
  lazyRetry(() => import('modules/manageDeals/pages/MyDeals'))
);
const ManagePendencies = React.lazy(() =>
  lazyRetry(() => import('modules/manageDeals/pages/Pendencies'))
);
const DealInfos = React.lazy(() =>
  lazyRetry(() => import('modules/manageDeals/pages/DealInfos'))
);
const FinancialGeneralView = React.lazy(() =>
  lazyRetry(() => import('modules/financial/pages/GeneralView'))
);
const FinancialNewEntry = React.lazy(() =>
  lazyRetry(() => import('modules/financial/pages/NewEntry'))
);
const FinancialVendorsAnalysis = React.lazy(() =>
  lazyRetry(() => import('modules/financial/pages/VendorsAnalysis'))
);
const FinancialConfiguration = React.lazy(() =>
  lazyRetry(() => import('modules/financial/pages/Configurations'))
);

//BILLINGS
const Billings = React.lazy(() =>
  lazyRetry(() => import('modules/billings/pages/MyBillings'))
);
//MOBIONE
const MobiOneSettings = React.lazy(() =>
  lazyRetry(() => import('modules/mobiOne/pages/Settings'))
);
const MobiOneStoreInvetory = React.lazy(() =>
  lazyRetry(() => import('modules/mobiOne/pages/StoreInvetory'))
);
const MobiOneFinancial = React.lazy(() =>
  lazyRetry(() => import('modules/mobiOne/pages/Financial'))
);
const MobioneAddVehicle = React.lazy(() =>
  lazyRetry(() => import('modules/mobiOne/pages/AddVehicle'))
);

const MobioneEditVehicle = React.lazy(() =>
  lazyRetry(() => import('modules/mobiOne/pages/EditVehicle'))
);

const MobioneVehicleDetails = React.lazy(() =>
  lazyRetry(() => import('modules/mobiOne/pages/VehicleDetails'))
);

const MobioneForwardingAgent = React.lazy(() =>
  lazyRetry(() => import('modules/mobiOne/pages/ForwardingAgent'))
);

const MobioneInvoice = React.lazy(() =>
  lazyRetry(() => import('modules/mobiOne/pages/Invoice'))
);

const SagaSeminovosIframe = React.lazy(() =>
  lazyRetry(() => import('modules/sagaSeminovosIframe'))
);

//CRM
const CrmCampaigns = React.lazy(() =>
  lazyRetry(() => import('modules/crm/pages/campaigns'))
);

const CrmCreateCampaign = React.lazy(() =>
  lazyRetry(() => import('modules/crm/pages/newCampaign'))
);

const CrmUpdateCampaign = React.lazy(() =>
  lazyRetry(() => import('modules/crm/pages/newCampaign'))
);

const Automation = React.lazy(() =>
  lazyRetry(() => import('modules/automation/pages/Automation'))
);

//MOBI_ANALYTICS
const MobiAnalytics = React.lazy(() =>
  lazyRetry(() => import('modules/mobiAnalytics'))
);
//MECHANICAL_WARRANTY
const MechanicalWarranty = React.lazy(() =>
  lazyRetry(() => import('modules/mechanicalWarranty'))
);

const estoqueRoutes = [
  {
    exact: true,
    path: routesPath.myDeals,
    Component: MyDeals,
    key: 'MyDeals',
  },
  {
    exact: true,
    path: routesPath.updateDeal,
    Component: DealForm,
    key: 'UpdateAnuncio',
  },
  {
    exact: true,
    path: routesPath.dealForm,
    Component: DealForm,
    key: 'DealForm',
  },
  {
    exact: true,
    path: routesPath.storeInvetory,
    Component: StoreInventory,
    key: 'StoreInventory',
  },
  {
    exact: true,
    path: routesPath.corporateSalesManagerConfig,
    Component: CorporateSalesManagerConfig,
    key: 'corporateSalesManager',
  },
  {
    exact: true,
    path: routesPath.stockPublicationSettings,
    Component: StockPublicationSettings,
    key: 'StockPublicationSettings',
  },
];

const redirectRoute = [
  {
    exact: true,
    path: routesPath.redirectPath,
    Component: RedirectPage,
    key: 'mobigestor-redirect',
  },
];

const settingsRoutes = [
  {
    exact: true,
    path: routesPath.formDealer,
    Component: FormDealer,
    key: 'FormDealer',
  },
];

const menuRoutes = [
  // {
  //   exact: true,
  //   path: routesPath.myDealBase,
  //   Component: MyDeals,
  //   key: 'estoque',
  // },
  // {
  //   exact: true,
  //   path: routesPath.bills,
  //   Component: Bill,
  //   key: 'faturas',
  // },
  // {
  //   exact: true,
  //   path: routesPath.settings,
  //   Component: Settings,
  //   key: 'settings',
  // },
  // {
  //   exact: true,
  //   path: routesPath.mobigestorHome,
  //   Component: Home,
  //   key: 'paineldigitalHome',
  // },
  {
    exact: true,
    path: routesPath.mobifi,
    Component: MobiFi,
    key: 'mobiFi',
  },
  {
    exact: true,
    path: routesPath.mobigestorHomeByDealerId,
    Component: Home,
    key: 'paineldigitalHomeByDealerId',
  },
  // FEIRÃO
  {
    exact: true,
    path: routesPath.feirao,
    Component: Feirao,
    key: 'feirao',
  },
  //FINANCIAL
  {
    exact: true,
    path: routesPath.manageDeals,
    Component: ManageDeals,
    key: 'modules/manageDeals',
  },
  {
    exact: true,
    path: routesPath.goalManager,
    Component: GoalManager,
    key: 'goalManager',
  },
  {
    exact: true,
    path: routesPath.proposals,
    Component: Propostas,
    key: 'propostas',
  },
  {
    exact: true,
    path: routesPath.conversations,
    Component: Conversations,
    key: 'conversations',
  },
  {
    exact: true,
    path: routesPath.leadQualifier,
    Component: LeadQualifier,
    key: 'leadQualifier',
  },
  {
    exact: true,
    path: routesPath.actionPlansByDealerId,
    Component: () => <ActionPlans insideStore={true} />,
    key: 'actionPlans',
  },
  {
    exact: true,
    path: routesPath.approvals,
    Component: Approvals,
    key: 'propostas',
  },
  {
    exact: true,
    path: routesPath.reception,
    Component: Reception,
    key: 'reception',
  },
  {
    exact: true,
    path: routesPath.manageReports,
    Component: ManageReports,
    key: 'Relatórios',
  },
  {
    exact: true,
    path: routesPath.proposalsConfig,
    Component: ProposalsConfig,
    key: 'propostasConfig',
  },
  {
    exact: true,
    path: routesPath.solutions,
    Component: Solutions,
    key: 'solutions',
  },
  {
    exact: true,
    path: routesPath.mobiPhone,
    Component: MobiPhone,
    key: 'mobifone',
  },
  {
    exact: true,
    path: routesPath.dealerSite,
    Component: DealerSite,
    key: 'dealerSite',
  },
  {
    exact: true,
    path: routesPath.performance,
    Component: Performance,
    key: 'Performance',
  },
  {
    exact: true,
    path: routesPath.manageProviders,
    Component: GerenciarPortais,
    key: 'integracoes',
  },
  {
    exact: true,
    path: routesPath.resaleSchedulerContacts,
    Component: ResaleSchedulerContacts,
    key: 'ResaleSchedulerContacts',
  },
  {
    exact: true,
    path: routesPath.contactsCustomers,
    Component: ContactList,
    key: 'ContactListCustomers',
  },
  {
    exact: true,
    path: routesPath.contactsSuppliers,
    Component: ContactList,
    key: 'ContactListSuppliers',
  },
  {
    exact: true,
    path: routesPath.editContact,
    Component: EditContact,
    key: 'EditContact',
  },
  {
    exact: true,
    path: routesPath.createContact,
    Component: CreateContact,
    key: 'CreateContact',
  },
  {
    exact: true,
    path: routesPath.financialGeneralView,
    Component: FinancialGeneralView,
    key: 'FinancialGeneralView',
  },
  {
    exact: true,
    path: routesPath.financialNewEntry,
    Component: FinancialNewEntry,
    key: 'FinancialNewEntry',
  },
  {
    exact: true,
    path: routesPath.financialVendorsAnalysis,
    Component: FinancialVendorsAnalysis,
    key: 'FinancialVendorsAnalysis',
  },
  {
    exact: true,
    path: routesPath.financialConfiguration,
    Component: FinancialConfiguration,
    key: 'FinancialConfiguration',
  },
  {
    exact: true,
    path: routesPath.managePendencies,
    Component: ManagePendencies,
    key: 'ManagePendencies',
  },
  {
    exact: true,
    path: routesPath.dealInfos,
    Component: DealInfos,
    key: 'DealInfos',
  },
  //Garantia Mecanica
  {
    exact: true,
    path: routesPath.mechanicalWarranty,
    Component: MechanicalWarranty,
    key: 'mechanicalWarranty',
  },
  //Mobione
  {
    exact: true,
    path: routesPath.mobiOneSettings,
    Component: MobiOneSettings,
    key: 'MobiOneSettings',
  },
  {
    exact: true,
    path: routesPath.mobiOneStoreInvetory,
    Component: MobiOneStoreInvetory,
    key: 'MobiOneStoreInvetory',
  },
  {
    exact: true,
    path: routesPath.mobiOneFinancial,
    Component: MobiOneFinancial,
    key: 'MobiOneFinancial',
  },
  {
    exact: true,
    path: routesPath.mobiOneStoreInvetoryAddVehicle,
    Component: MobioneAddVehicle,
    key: 'mobioneAddVehicle',
  },
  {
    exact: true,
    path: routesPath.mobiOneStoreInvetoryEditVehicle,
    Component: MobioneEditVehicle,
    key: 'mobioneEditVehicle',
  },
  {
    exact: true,
    path: routesPath.mobiOneForwardingAgent,
    Component: MobioneForwardingAgent,
    key: 'mobiOneForwardingAgent',
  },
  {
    exact: true,
    path: routesPath.mobiOneInvoice,
    Component: MobioneInvoice,
    key: 'mobioneInvoice',
  },
  {
    exact: true,
    path: routesPath.mobiOneVehicleDetails,
    Component: MobioneVehicleDetails,
    key: 'mobioneVehicleDetails',
  },
];

const conversaProposta = [
  // {
  //   exact: true,
  //   path: routesPath.chatProposal,
  //   Component: ChatProposal,
  //   key: 'ChatProposal',
  // },
  {
    exact: true,
    path: routesPath.proposalOrderById,
    Component: OrderSteps,
    key: 'ProposalOrderById',
  },
];

const firstAcessRoutes = [
  {
    exact: true,
    path: routesPath.firstAccess,
    Component: FirstAccess,
    key: 'FirstAccess',
  },
];

// const improvementsDeals = [
//   {
//     exact: true,
//     path: routesPath.myDealsImprovements,
//     Component: MyDeals,
//     key: 'MeusAnunciosImprovement',
//   },
// ];

// const pathByDealer = [
//   {
//     exact: true,
//     path: routesPath.chatProposalByProposalId,
//     Component: ChatProposal,
//     key: 'ChatProposalByProposalId',
//   },
//   {
//     exact: true,
//     path: routesPath.chatProposalCallCenter,
//     Component: ChatProposal,
//     key: 'ChatProposalCallCenter',
//     data: {
//       hiddeMenuAndHeaderItens: true,
//       groupTitle: 'Central de atendimento',
//     },
//   },
//   {
//     exact: true,
//     path: routesPath.chatProposalSearchAndRescue,
//     Component: ChatProposal,
//     key: 'ChatProposalSearchAndRescue',
//     data: {
//       hiddeMenuAndHeaderItens: true,
//       groupTitle: 'Busca e resgate',
//     },
//   },
// ];

const welcomeRoutes = [
  {
    exact: true,
    path: routesPath.welcome,
    Component: Welcome,
    key: 'Welcome',
  },
];

const error500Routes = [
  {
    exact: true,
    path: routesPath.error500,
    Component: Error500,
    key: 'Error500',
  },
];

const myResellersRoutes = [
  {
    exact: true,
    path: routesPath.myResellers,
    Component: MyResellers,
    key: 'MyResellers',
    data: {
      hiddeMenuAndHeaderItens: true,
    },
  },
];

const checkAndRedirectRoute = [
  {
    exact: true,
    path: routesPath.checkAndRedirect,
    Component: CheckAndRedirect,
    key: 'checkAndRedirect',
    data: {
      hiddeMenuAndHeaderItens: true,
    },
  },
];

const callCenterRoute = [
  {
    exact: true,
    path: routesPath.callCenter,
    Component: CallCenter,
    key: 'CallCenter',
    data: {
      hiddeMenuAndHeaderItens: true,
      groupTitle: 'Central de atendimento',
    },
  },
];

const searchAndRescueCenterRoute = [
  {
    exact: true,
    path: routesPath.searchAndRescueCenter,
    Component: SearchAndRescueCenter,
    key: 'SearchAndRescueCenter',
    data: {
      hiddeMenuAndHeaderItens: true,
      groupTitle: 'Busca e resgate',
    },
  },
];

const viewGroupRoute = [
  {
    exact: true,
    path: routesPath.groupDashboard,
    Component: GroupViewDashboard,
    key: 'GroupViewDashboard',
  },
  {
    exact: true,
    path: routesPath.groupViewStock,
    Component: GroupViewStock,
    key: 'GroupViewStock',
  },
  {
    exact: true,
    path: routesPath.groupViewProposals,
    Component: GroupViewProposals,
    key: 'GroupViewProposals',
  },
  {
    exact: true,
    path: routesPath.groupViewApprovals,
    Component: GroupViewApprovals,
    key: 'GroupViewApprovals',
  },
  {
    exact: true,
    path: routesPath.groupViewEvaluations,
    Component: GroupViewEvaluations,
    key: 'GroupViewEvaluations',
  },
  {
    exact: true,
    path: routesPath.groupViewMobiPhone,
    Component: GroupViewMobiPhone,
    key: 'GroupViewMobiPhone',
  },
  {
    exact: true,
    path: routesPath.groupViewActionPlans,
    Component: GroupViewActionPlans,
    key: 'GroupViewActionPlans',
  },
  {
    exact: true,
    path: routesPath.groupViewActionPlansByDealerId,
    Component: GroupViewStoreActionPlan,
    key: 'GroupViewActionPlansByDealerId',
  },
  {
    exact: true,
    path: routesPath.groupViewBillingCenter,
    Component: GroupViewBillingCenter,
    key: 'GroupViewBillingCenter',
  },
];

const resaleSellRoutes = [
  {
    exact: true,
    path: routesPath.resaleStock,
    Component: ResaleStock,
    key: 'ResaleStock',
  },
  {
    exact: true,
    path: routesPath.resaleDealForm,
    Component: ResaleDealForm,
    key: 'ResaleDealForm',
  },
  {
    exact: true,
    path: routesPath.resaleDeaFormEdit,
    Component: ResaleDealForm,
    key: 'ResaleDeaFormEdit',
  },
  {
    exact: true,
    path: routesPath.resaleProposals,
    Component: ResaleProposals,
    key: 'ResaleProposals',
  },
  {
    exact: true,
    path: routesPath.resaleProposalsByDeal,
    Component: ResaleProposalsByDeal,
    key: 'ResaleProposalsByDeal',
  },
];

const resaleBuyRoutes = [
  {
    exact: true,
    path: routesPath.resaleBuyOfferPortal,
    Component: ResaleOfferPortal,
    key: 'ResaleOfferPortal',
  },
  {
    exact: true,
    path: routesPath.resaleBuyOfferPortalByDealer,
    Component: ResaleOfferPortal,
    key: 'ResaleOfferPortalByDealer',
  },
  {
    exact: true,
    path: routesPath.resaleDealDetails,
    Component: ResaleDealDetails,
    key: 'ResaleDealDetails',
  },
  {
    exact: true,
    path: routesPath.resaleProposalSendSuccessful,
    Component: ResaleSendSuccessful,
    key: 'ResaleSendSuccessful',
  },
  {
    exact: true,
    path: routesPath.resalePurchaseSuccessful,
    Component: ResalePurchaseSuccessful,
    key: 'ResalePurchaseSuccessful',
  },
  {
    exact: true,
    path: routesPath.resaleBuyProposals,
    Component: ResaleBuyProposals,
    key: 'ResaleBuyProposals',
  },
  {
    exact: true,
    path: routesPath.resaleBuyNegotiationByDeal,
    Component: ResaleBuyNegotiationByDeal,
    key: 'ResaleBuyNegotiationByDeal',
  },
];

const resaleConfigRoutes = [
  {
    exact: true,
    path: routesPath.resaleConfig,
    Component: ResaleConfig,
    key: 'ResaleConfig',
  },
];

const evaluationRoutes = [
  {
    exact: true,
    path: routesPath.newEvaluation,
    Component: NewEvaluation,
    key: 'NewEvaluation',
  },
  {
    exact: true,
    path: routesPath.evaluations,
    Component: EvaluationList,
    key: 'EvaluationList',
  },
  {
    exact: true,
    path: routesPath.evaluationReports,
    component: EvaluationReports,
    key: 'evaluationReports',
  },
  {
    exact: true,
    path: routesPath.evaluationConfig,
    Component: EvaluationConfig,
    key: 'evaluationgeneralview',
  },
];

const patioSystemControlRoutes = [
  {
    exact: true,
    path: routesPath.patioSystemControl,
    Component: PatioSystemControl,
    key: 'PatioSystemControl',
  },
  {
    exact: true,
    path: routesPath.patioRegister,
    Component: PatioRegister,
    key: 'PatioRegister',
  },
  {
    exact: true,
    path: routesPath.patioEdit,
    Component: PatioEdit,
    key: 'PatioEdit',
  },
  {
    exact: true,
    path: routesPath.successfulRegistration,
    Component: SuccessfulRegistration,
    key: 'SuccessfulRegistration',
  },
  {
    exact: true,
    path: routesPath.patioVehicleDetails,
    Component: PatioVehicleDetails,
    key: 'PatioVehicleDetails',
  },
];

const financingRoutes = [
  {
    exact: true,
    path: routesPath.financing,
    Component: Financing,
    key: 'FinancingList',
  },
  {
    exact: true,
    path: routesPath.newFinancingSteps,
    Component: NewFinancingSteps,
    key: 'NewFinancingSteps',
  },
  {
    exact: true,
    path: routesPath.financingDetailsByFinancingId,
    Component: NewFinancingSteps,
    key: 'FinancingDetailsStep',
  },
  //FINANCIAL_INSTITUTIONS
  {
    exact: true,
    path: routesPath.financialInstitutionsList,
    Component: FinancialInstitutionsList,
    key: 'FinancialInstitutionsList',
  },
  {
    exact: true,
    path: routesPath.newFinancialInstitution,
    Component: NewFinancialInstitution,
    key: 'NewFinancialInstitution',
  },
  {
    exact: true,
    path: routesPath.financialInstitutionDetails,
    Component: FinancialInstitutionDetails,
    key: 'FinancialInstitutionDetails',
  },
  {
    exact: true,
    path: routesPath.newCampaign,
    Component: NewCampaign,
    key: 'NewCampaign',
  },
  {
    exact: true,
    path: routesPath.editCampaign,
    Component: EditCampaign,
    key: 'EditCampaign',
  },
  {
    exact: true,
    path: routesPath.newTable,
    Component: NewTable,
    key: 'NewTable',
  },
  {
    exact: true,
    path: routesPath.editTable,
    Component: EditTable,
    key: 'EditTable',
  },
];

const route404 = [
  {
    exact: false,
    path: '*',
    Component: Error404,
    key: '404',
  },
];

const billing = [
  {
    exact: true,
    path: routesPath.billings,
    Component: Billings,
    key: 'Billings',
  },
];

const sagaSeminovosIframe = [
  {
    exact: true,
    path: routesPath.sagaSeminovosIframe,
    Component: SagaSeminovosIframe,
    key: 'sagaSeminovos',
  },
];

const crmCampaigns = [
  {
    exact: true,
    path: routesPath.crmCampaigns,
    Component: CrmCampaigns,
    key: 'CrmCampaigns',
  },
  {
    exact: true,
    path: routesPath.crmCreateCampaign,
    Component: CrmCreateCampaign,
    key: 'CrmCreateCampaign',
  },
  {
    exact: true,
    path: routesPath.crmUpdateCampaign,
    Component: CrmUpdateCampaign,
    key: 'CrmUpdateCampaign',
  },
];

const automation = [
  {
    exact: true,
    path: routesPath.automation,
    Component: Automation,
    key: 'Automation',
  },
];

const mobiAnalytics = [
  {
    exact: true,
    path: routesPath.mobiAnalytics,
    Component: MobiAnalytics,
    key: 'mobiAnalytics',
  },
];

const routesWithConsultor = [
  ...welcomeRoutes,
  ...checkAndRedirectRoute,
  ...myResellersRoutes,
  ...viewGroupRoute,
  ...callCenterRoute,
  ...billing,
  ...searchAndRescueCenterRoute,
  ...menuRoutes,
  ...estoqueRoutes,
  ...settingsRoutes,
  ...conversaProposta,
  ...firstAcessRoutes,
  // ...pathByDealer,
  ...financingRoutes,
  ...resaleSellRoutes,
  ...resaleConfigRoutes,
  ...resaleBuyRoutes,
  ...evaluationRoutes,
  ...patioSystemControlRoutes,
  ...sagaSeminovosIframe,
  ...crmCampaigns,
  ...automation,
  ...mobiAnalytics,
  ...error500Routes,
  ...route404,
];

export const functionRoutesWithDealer = (hasResaleBuy, hasResaleSell) => {
  const withDealerRoutes = [
    ...welcomeRoutes,
    ...checkAndRedirectRoute,
    ...myResellersRoutes,
    ...viewGroupRoute,
    ...billing,
    ...sagaSeminovosIframe,
    ...callCenterRoute,
    ...searchAndRescueCenterRoute,
    ...menuRoutes,
    ...estoqueRoutes,
    ...settingsRoutes,
    ...conversaProposta,
    ...firstAcessRoutes,
    ...crmCampaigns,
    ...automation,
    // ...pathByDealer,
    ...evaluationRoutes,
    ...financingRoutes,
    ...mobiAnalytics,
    ...error500Routes,
  ];

  //if dealer have buy and sell product
  if (hasResaleBuy && hasResaleSell) {
    return [
      ...withDealerRoutes,
      ...resaleSellRoutes,
      ...resaleBuyRoutes,
      ...resaleConfigRoutes,
    ];
  }

  //if dealer have some resale buy product
  if (hasResaleBuy) {
    return [...withDealerRoutes, ...resaleBuyRoutes];
  }

  //if dealer have some resale sell product
  if (hasResaleSell) {
    return [...withDealerRoutes, ...resaleSellRoutes, ...resaleConfigRoutes];
  }
  //if dealer no have resale product
  return withDealerRoutes;
};

const routesWithDealer = [...functionRoutesWithDealer(), ...route404];

const routesWithoutDealer = [...welcomeRoutes, ...error500Routes, ...route404];

const mobigestorRoutes = [
  {
    path: routesPath.mobigestorHome,
    Component: KeycloakAuth,
    key: 'dash',
    requireAuth: true,
    routes: routesWithConsultor,
  },
];

const mobigestorWithoutDealer = [
  {
    path: routesPath.mobigestorHome,
    Component: KeycloakAuth,
    key: 'dash',
    requireAuth: true,
    routes: routesWithoutDealer,
  },
];

const homeRoute = [
  {
    exact: true,
    path: routesPath.landing,
    Component: Landing,
    key: 'Landing',
  },
];

const authorizationDealerRoute = [
  {
    exact: true,
    path: routesPath.actionvationDealer,
    Component: ActivationDealer,
    key: 'ActivationDealer',
  },
];

const routes = [
  ...homeRoute,
  ...authorizationDealerRoute,
  ...redirectRoute,
  ...mobigestorRoutes,
];

export default routes;
export {
  mobigestorRoutes,
  mobigestorWithoutDealer,
  routesWithConsultor,
  routesWithDealer,
  routesWithoutDealer,
};
