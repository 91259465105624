import ProposalsListServices from 'modules/resale/services/ProposalsListServices';
import ProposalListCorporativeSaleServices from 'modules/resale/services/ProposalListCorporativeSaleServices';
import NegotiationServices from 'modules/resale/services/NegotiationServices';
import NegotiationCorporativeSaleServices from 'modules/resale/services/NegotiationCorporativeSaleServices';

export const PORTAL_COLABORADOR_VENDA_CORPORATIVA_IDS = [83561, 85631];

export function ProposalServicesToggleEndpoints(dealerId) {
  return PORTAL_COLABORADOR_VENDA_CORPORATIVA_IDS.includes(Number(dealerId))
    ? ProposalListCorporativeSaleServices
    : ProposalsListServices;
}

export function NegotiationServicesToggleEndpoints(dealerId) {
  return PORTAL_COLABORADOR_VENDA_CORPORATIVA_IDS.includes(Number(dealerId))
    ? NegotiationCorporativeSaleServices
    : NegotiationServices;
}

export function handleReturnConfigByDealerId({
  dealerId,
}: {
  dealerId: string | number;
}) {
  return PORTAL_COLABORADOR_VENDA_CORPORATIVA_IDS.includes(Number(dealerId));
}
