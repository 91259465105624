import EnvVars from 'helpers/envVars';
import useUserId from 'hooks/useUserId';

import useDealer from './useDealer';
import useDealerId from './useDealerId';

export default function usePrimeiraMao() {
  const dealerId = useDealerId();
  const userId = useUserId();
  const dealer = useDealer();

  const hasPrimeiraMao = (dealer?.website || '')?.includes(
    'primeiramaosaga.com.br'
  );

  const uri = `${EnvVars(
    'SELLER_SAGA_SEMINOVOS'
  )}?dealerId=${dealerId}&sellerId=${userId}`;

  return { hasPrimeiraMao, uri };
}
