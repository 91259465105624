import React, { memo, useState, useEffect, useCallback } from 'react';
import { useChatContext } from 'pages/chats/ChatsContext';
import { useDispatch } from 'react-redux';
import proposalServices from 'services/proposalServices';
import ChooseVehicleDialog from 'components/molecules/ChooseVehicleDialog';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import ItemHeader from '../atoms/ItemHeader';
import VehicleItem from './VehicleItem';
import { Button } from './styles';

const VehicleInfos = props => {
  const [state, setState] = useState({
    openModalAddVehicle: false,
    update: false,
  });

  const {
    currentProposal,
    getInterestProducts,
    otherStates,
  } = useChatContext();

  const dispatch = useDispatch();

  const handleClickOpenModalAddVehicle = () => {
    setState(prevState => ({
      ...prevState,
      openModalAddVehicle: !state.openModalAddVehicle,
    }));
  };

  const setVehicleToCurrentProposal = useCallback(
    async currentVehicleOfInterest => {
      try {
        const response = await proposalServices.addInterestProduct(
          currentProposal.dealerId,
          currentProposal.id,
          currentVehicleOfInterest
        );
        if (response.success) {
          await getInterestProducts();
          dispatch(
            UIActionCreators.snackbarManagement(
              'success',
              'Produto de interesse adicionado com sucesso.'
            )
          );
          setState(prevState => ({ ...prevState, openModalAddVehicle: false }));
        }
      } catch {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Ocorreu um erro ao adicionar um produto de interesse.'
          )
        );
      }
    },
    [
      currentProposal.dealerId,
      currentProposal.id,
      dispatch,
      getInterestProducts,
    ]
  );

  const confirm = async currentVehicle => {
    if (currentVehicle.type === '0km') {
      setVehicleToCurrentProposal({ trim: { id: currentVehicle.id } });
      return;
    }
    if (currentVehicle.id) {
      setVehicleToCurrentProposal({ dealId: currentVehicle.id });
      return;
    }
    setVehicleToCurrentProposal(currentVehicle);
  };

  const handleClickCloseModalAddVehicle = choosenVehicles => {
    if (choosenVehicles?.[0]) {
      confirm(choosenVehicles[0]);
      return;
    }
    setState(prevState => ({ ...prevState, openModalAddVehicle: false }));
  };

  const handleClickUpdate = () => {
    setState(prevState => ({ ...prevState, update: true }));
  };

  useEffect(() => {
    getInterestProducts();
  }, []);

  return (
    <>
      <ItemHeader>Veiculo de interesse</ItemHeader>
      <div style={{ padding: 16 }}>
        {otherStates.interestProducts &&
          otherStates.interestProducts.map((vehicle, id) => {
            return (
              <VehicleItem
                vehicle={vehicle}
                key={id}
                id={id}
                handleClickUpdate={handleClickUpdate}
                vehicleNumber={id}
              />
            );
          })}
        {!props.closedProposal && (
          <Button onClick={handleClickOpenModalAddVehicle}>
            Adicionar veículo
          </Button>
        )}
      </div>

      {state.openModalAddVehicle && (
        <ChooseVehicleDialog
          open={state.openModalAddVehicle}
          onClose={handleClickCloseModalAddVehicle}
          allAvailable
        />
      )}
    </>
  );
};

export default memo(VehicleInfos);
