import React, { useCallback, useRef } from 'react';
import {
  Send as SendIcon,
  Room as RoomIcon,
  AttachFile as AttachFileIcon,
  InsertDriveFile as InsertDriveFileIcon,
  DriveEtaOutlined as DriveEtaOutlinedIcon,
  InsertEmoticonOutlined as InsertEmoticonOutlinedIcon,
} from '@material-ui/icons';
import { InputBase, IconButton } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from 'bit/mobiauto.web-ui-components.components.icon';

// Asset import
import { ReactComponent as Gallery } from 'assets/gallery.svg';
import { ReactComponent as TemplateIcon } from 'assets/bubble-icon.svg';
import { ReactComponent as MicrophoneIcon } from 'assets/microphone.svg';

// Helper import
import isEmpty from 'helpers/isEmpty';

// Component import
import { EmojiPicker } from 'components/EmojiPicker';
import ThemeButton from 'components/atoms/ThemeButton';
import CarPictureDialog from './components/CarPicturesDialog';
import { TemplatesDialog } from 'modules/messageTemplate/components/TemplatesDialog';

// Style import
import {
  styles,
  TextInputContainer,
  TextInput,
  AnimatedLeftToRight,
  TimerContainer,
  IsRecording,
  CancelSpan,
  PreviewImg,
  RemoveButton,
} from './styles';

const TextInputMessageComponent = ({
  classes,
  isRecording,
  imagesArray,
  messageText,
  sendMessage,
  handleKeyPress,
  handleOnChangeMessage,
  openTemplateDialog,
  setOpenTemplateDialog,
  handleClickSendTemplateMessage,
  handleOnChangeChooseMedia,
  handleClickSendWhatsappLocation,
  handleOnClickToUploadImage,
  handleToggleMicrophone,
  stopRecordingAndClean,
  timer,
  handleOnClickSendMedia,
  handleOnCancelImages,
  handleRemoveImageByUrl,
  imageInputRef,
  handleOnChangeChooseImage,
  mediaInputRef,
  handleOnClickToUploadMedia,
  canSendWhatsappBusinessMessage,
  currentChannel,
  openCarPictureDialog,
  setOpenCarPictureDialog,
  handleWhatsappEvent,
  disabled = false,
  messageType,
  emojiPickerIsOpen,
  toggleEmojiPicker,
}) => {
  const inputRef = useRef(null);

  const handleEmojiClick = useCallback(
    emojiData => {
      const emoji = emojiData.emoji;
      const prefix = messageText.substring(0, inputRef.current?.selectionStart);
      const suffix = messageText.substring(inputRef.current?.selectionEnd);
      const formattedMessage = `${prefix} ${emoji} ${suffix}`;

      handleOnChangeMessage({ target: { value: formattedMessage } });
    },
    [messageText, inputRef]
  );

  return (
    <TextInputContainer>
      <div style={{ display: 'none' }}>
        <input
          multiple
          type="file"
          accept="image/*"
          ref={imageInputRef}
          onChange={handleOnClickToUploadImage}
        />
      </div>
      <div style={{ display: 'none' }}>
        <input
          multiple
          type="file"
          // accept="image/*"
          ref={mediaInputRef}
          onChange={handleOnClickToUploadMedia}
        />
      </div>
      {canSendWhatsappBusinessMessage ? (
        <TextInput>
          {isRecording ? (
            <>
              <TimerContainer>
                <IsRecording>
                  <MicrophoneIcon style={{ fill: 'red' }} />
                </IsRecording>
                {('0' + timer.minute).slice(-2)}:
                {('0' + timer.second).slice(-2)}
              </TimerContainer>
              <IconButton
                classes={{ root: classes.root }}
                onClick={stopRecordingAndClean}
              >
                <CancelSpan>Cancelar</CancelSpan>
              </IconButton>

              <IconButton
                classes={{ root: classes.root }}
                onClick={handleToggleMicrophone}
              >
                <SendIcon />
              </IconButton>
            </>
          ) : !isEmpty(imagesArray) ? (
            <>
              <TimerContainer>
                {imagesArray.map(item => (
                  <PreviewImg key={item.previewUrl}>
                    <RemoveButton
                      onClick={() => handleRemoveImageByUrl(item.previewUrl)}
                    >
                      <Icon name="Close" size="18" />
                    </RemoveButton>
                    {messageType === 'MEDIA' ? (
                      <div style={{ width: 40 }}>
                        <InsertDriveFileIcon />
                      </div>
                    ) : (
                      <img
                        src={item.previewUrl}
                        style={{ width: 70, height: 50, marginRight: 8 }}
                      />
                    )}
                  </PreviewImg>
                ))}
              </TimerContainer>
              <IconButton
                classes={{ root: classes.root }}
                onClick={handleOnCancelImages}
              >
                <CancelSpan>Cancelar</CancelSpan>
              </IconButton>
              <IconButton
                classes={{ root: classes.root }}
                onClick={handleOnClickSendMedia}
              >
                <SendIcon />
              </IconButton>
            </>
          ) : (
            <>
              <AnimatedLeftToRight>
                <InputBase
                  classes={{ root: classes.input }}
                  value={messageText}
                  placeholder={`Digite sua mensagem e tecle "Enter" para enviar`}
                  multiline
                  rowsMax="3"
                  onChange={handleOnChangeMessage}
                  onKeyPress={handleKeyPress}
                  disabled={disabled}
                  inputRef={inputRef}
                />
              </AnimatedLeftToRight>
              {!disabled && (
                <>
                  {currentChannel === 'WHATSAPP' && (
                    <>
                      {/* //LOCATION */}
                      <IconButton
                        classes={{ root: classes.textInputButtons }}
                        onClick={handleClickSendWhatsappLocation}
                      >
                        <RoomIcon />
                      </IconButton>
                    </>
                  )}
                  {/* //FOTOS */}
                  <IconButton
                    classes={{ root: classes.textInputButtons }}
                    onClick={() => setOpenCarPictureDialog(true)}
                  >
                    <DriveEtaOutlinedIcon />
                  </IconButton>
                  {/* //MEDIA */}
                  <IconButton
                    classes={{ root: classes.textInputButtons }}
                    onClick={() =>
                      setOpenTemplateDialog({
                        open: true,
                        isFirst: false,
                      })
                    }
                  >
                    <TemplateIcon />
                  </IconButton>
                  <>
                    {/* //MEDIA */}
                    <IconButton
                      classes={{ root: classes.textInputButtons }}
                      onClick={handleOnChangeChooseMedia}
                    >
                      <AttachFileIcon />
                    </IconButton>
                    {/* //GALLERY */}
                    <IconButton
                      classes={{ root: classes.textInputButtons }}
                      onClick={handleOnChangeChooseImage}
                    >
                      <Gallery />
                    </IconButton>
                    {/* //MICROPHONE */}
                    <IconButton
                      classes={{ root: classes.textInputButtons }}
                      onClick={handleToggleMicrophone}
                    >
                      <MicrophoneIcon style={{ fill: '#757575' }} />
                    </IconButton>

                    {/* Emojis */}
                    <IconButton
                      classes={{ root: classes.textInputButtons }}
                      onClick={toggleEmojiPicker}
                    >
                      <InsertEmoticonOutlinedIcon />
                    </IconButton>
                  </>
                  <IconButton
                    classes={{ root: classes.textInputButtons }}
                    onClick={sendMessage}
                  >
                    <SendIcon />
                  </IconButton>
                </>
              )}
            </>
          )}
        </TextInput>
      ) : (
        <div style={{ margin: '3.5px 0px' }}>
          <ThemeButton
            onClick={() =>
              disabled
                ? null
                : setOpenTemplateDialog({
                    open: true,
                    isFirst: true,
                  })
            }
            fullWidth
          >
            Iniciar conversa por whatsapp
          </ThemeButton>
        </div>
      )}
      {openTemplateDialog.open && (
        <TemplatesDialog
          channel={currentChannel}
          isOpen={openTemplateDialog.open}
          onClose={() => setOpenTemplateDialog({ open: false, isFirst: false })}
          onSubmit={message => {
            if (openTemplateDialog.isFirst) handleWhatsappEvent();

            handleClickSendTemplateMessage(message);
            setOpenTemplateDialog({ open: false, isFirst: false });
          }}
        />
      )}
      {openCarPictureDialog && (
        <CarPictureDialog
          open={openCarPictureDialog}
          handleOnClose={() => setOpenCarPictureDialog(false)}
          handleClickImage={handleOnClickToUploadImage}
        />
      )}

      {emojiPickerIsOpen && (
        <EmojiPicker
          onMouseLeave={toggleEmojiPicker}
          onEmojiClick={emojiData => handleEmojiClick(emojiData)}
        />
      )}
    </TextInputContainer>
  );
};

export default withStyles(styles)(TextInputMessageComponent);
